import { Button, Link } from '@mui/material';
import React from 'react';
import { helpCenterUrl } from '../../constants';

const HelpCenterLink = () => (
  <Button
    size="small"
    id="help-center"
    disableTouchRipple
    sx={{
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    }}
  >
    <Link
      href={helpCenterUrl}
      target="_blank"
      color="text.main"
      variant="body1"
    >
      Centro de ayuda
    </Link>
  </Button>
);

export default HelpCenterLink;
