import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Person from '@mui/icons-material/Person';
import { Badge, Link } from '@mui/material';
import { NavbarButton } from '../../buttons';

const ProfileNavItem = ({ credentialsMissing, profileMissing }) => (
  <Link component={NavLink} to="/app/profile">
    <NavbarButton id="nav-profile">
      <Badge
        invisible={!credentialsMissing && !profileMissing}
        badgeContent={credentialsMissing + profileMissing}
        color="primary"
      >
        <Person sx={{ color: 'white' }} />
      </Badge>
    </NavbarButton>
  </Link>
);

ProfileNavItem.propTypes = {
  credentialsMissing: PropTypes.number.isRequired,
  profileMissing: PropTypes.number.isRequired,
};

export default ProfileNavItem;
