import { useMutation, useReactiveVar } from '@apollo/client';
import Cached from '@mui/icons-material/Cached';
import { Box, Button } from '@mui/material';
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ORDERING_DOCUMENTLIST_TYPE } from '../../constants';
import { EXPORT_INVOICES, LOAD_NEW_INVOICES, CHANGE_COMPANY } from '../../graphql';
import { cleanObjectFromUndefined, formatYearMonthDay } from '../../helpers';
import { getDefaultFiltersFromHistory, getFilterByNameFromHistory, historyToFilters, getAndEraseFiltersFromHistory } from '../../helpers/url-filter-translator';
import { useAvailableHeight, useExportDocument, useFilteredQuery, useGetUser, useIsMobile, useSelectedCompany } from '../../hooks';
import { ShoppingCartPreview } from '../banners';
import { DownloadExcelIconButton } from '../buttons';
import { FingoDataGrid } from '../dataGrids';
import { DocumentFilters } from '../filters';
import FirstLoadMessage from '../dataGrids/FirstLoadMessage';
import getErrorType from '../../helpers/document-list-error-types';
import { PaperHeader } from '../headers';
import { preofferSelected, shoppingCartShow, currencyVar } from '../../apollo/reactive-variables';
import DataGridNoRowsOverlay from '../dataGrids/DataGridNoRowsOverlay';
import useGetCountryFromUrl from '../../hooks/useGetCountryFromUrl';

const DocumentList = (
  {
    type,
    headerTitle,
    queryDocument,
    skipQueryDocument,
    exportType,
    queryExport,
    checkboxSelection,
    isRowSelectable,
    emitter,
    serverFilters,
    includeHeaders,
    mobileHeaders,
    updateTrigger,
    setUpdateTrigger,
    showExportInvoice,
    financingStatusOptions,
    allCompaniesOptions,
    customVariables,
    initialInvoiceFilters,
    shoppingCartPreview,
    showFilters,
    defaultFilterProps,
    customSummaryElement,
    onCompletedSetLength,
    onCompletedSetRows,
    initialOrderBy,
    preColumns,
    openJoyride,
    flexEndButtons,
    helpVideo,
    newData,
    readData,
    selectionModel,
    CustomHeader,
    customHeaderProps,
    firstLoad,
    countryFilter,
    initialPageSize,
    updateCustomVariables,
    setUpdateCustomVariables,
    showGoBack,
    disabledCheckboxTooltip,
    extraHeaderComponent,
    ...restDataGridProps
  },
) => {
  const history = useHistory();
  const {
    defaultPageSize,
    defaultPage,
    defaultDates,
    defaultGlobalFilter,
    defaultOrderBy,
    defaultFilters,
  } = getDefaultFiltersFromHistory(history, initialOrderBy);
  const [height, refChange] = useAvailableHeight();
  const [page, setPage] = useState(defaultPage || 0);
  const [pageSize, setPageSize] = useState(initialPageSize || defaultPageSize);
  const [globalFilter, _setGlobalFilter] = useState(defaultGlobalFilter);
  const [invoiceFilters, _setInvoiceFilters] = useState({
    ...defaultFilters, ...initialInvoiceFilters,
  });
  const [customVariablesStates, setCustomVariablesStates] = useState(customVariables);
  const [changeCompany] = useMutation(CHANGE_COMPANY, {
    onCompleted: () => shoppingCartShow(true) });
  const [openStateFilter, setOpenStateFilter] = useState(false);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [dates, setDates] = useState(defaultDates);
  const setGlobalFilter = useCallback((value) => {
    setPage(0);
    _setGlobalFilter(value);
  }, [_setGlobalFilter]);
  const currentUser = useGetUser();
  const selectedCompany = useSelectedCompany();
  const [loadNewInvoices] = useMutation(LOAD_NEW_INVOICES, {
    variables: {
      companyId: selectedCompany?.id,
    },
    onError: () => {},
  });
  const country = useGetCountryFromUrl();
  const commonVars = cleanObjectFromUndefined({
    ...invoiceFilters,
    first: pageSize,
    offset: page * pageSize,
    globalFilter,
    orderBy,
    receiverId: !emitter && !invoiceFilters.allCompanies ? selectedCompany?.id : null,
    companyId: !!emitter && !invoiceFilters.allCompanies ? selectedCompany?.id : getFilterByNameFromHistory(history, 'companyId'),
    allReceiverCompany: !emitter ? Boolean(invoiceFilters.allCompanies) : null,
    allIssuedCompany: emitter ? Boolean(invoiceFilters.allCompanies) : null,
    dateIssued_Gte: formatYearMonthDay(dates.dateIssued_Gte),
    dateIssued_Lte: formatYearMonthDay(dates.dateIssued_Lte),
    loanedStatus: invoiceFilters.loanedStatus,
    creditNoteStatus: invoiceFilters.creditNoteStatus,
    siiStatus: invoiceFilters.siiStatus,
    dteType_Code_In: invoiceFilters.documentType,
    dteType_CountryId: countryFilter ? country?.id : null,
    dfstateIn: invoiceFilters.documentFinanceStatus,
    ...customVariables,
    ...customVariablesStates,
  });

  const {
    data: rawData,
    loading,
    refetch: refetchDocuments,
    deletePaginatedAndRefetch,
  } = useFilteredQuery(
    queryDocument,
    {
      variables: commonVars,
      notifyOnNetworkStatusChange: true,
      skip: skipQueryDocument,
    },
  );

  // This section is dedicated to synchronize filters and urls
  const setInvoiceFilters = useCallback(
    (newState) => {
      _setInvoiceFilters(newState);
      historyToFilters(history, { ...commonVars, ...newState });
    },
    [historyToFilters, history, commonVars],
  );

  const readDataAndRefresh = useCallback(() => {
    deletePaginatedAndRefetch();
    readData();
  }, [readData, deletePaginatedAndRefetch]);
  const newUpdateDocuments = useCallback(() => {
    readDataAndRefresh();
    loadNewInvoices();
  }, [readDataAndRefresh, loadNewInvoices]);
  const data = rawData ? onCompletedSetRows(rawData) : [];
  const rowCount = rawData ? onCompletedSetLength(rawData) : 0;

  useEffect(() => {
    historyToFilters(history, commonVars);
    const { changeCompanyTo, preoffer } = getAndEraseFiltersFromHistory(history, 'changeCompanyTo', 'preoffer');
    if (changeCompanyTo) {
      changeCompany({ variables: { companyId: changeCompanyTo } });
      preofferSelected([preoffer]);
    }
  }, []);

  const currency = useReactiveVar(currencyVar);
  useEffect(() => {
    if (updateTrigger || currency) {
      refetchDocuments({ ...commonVars, currency });
      setUpdateTrigger(false);
    }
  }, [updateTrigger, currency]);

  useEffect(() => {
    if (updateCustomVariables) {
      setUpdateCustomVariables(false);
      setCustomVariablesStates(customVariables);
    }
  }, [updateCustomVariables]);

  useEffect(() => {
    if (firstLoad && newData) {
      readDataAndRefresh();
    }
  }, [firstLoad, newData]);

  const {
    exportDocuments: exportInvoices,
    loading: exportingDocuments,
  } = useExportDocument(queryExport, { ...commonVars, rowCount, exportType });

  const columns = preColumns.map((col) => {
    if (col.type === 'number') {
      return {
        ...col,
        filterOperators: getGridNumericOperators().filter(
          (operator) => ['=', '>', '>=', '<', '<='].includes(operator.value),
        ),
      };
    }
    if (col.type === 'date') {
      return {
        ...col,
        filterOperators: getGridDateOperators().filter(
          (operator) => ['is', 'after', 'onOrAfter', 'before', 'onOrBefore'].includes(operator.value),
        ),
      };
    }
    if (col.field === 'company_MasterEntity_Name' || col.field === 'receiver_Name') {
      return {
        ...col,
        filterOperators: getGridStringOperators().filter(
          (operator) => ['equals', 'contains', 'startsWith'].includes(operator.value),
        ),
      };
    }
    if (col.type === 'string') {
      return {
        ...col,
        filterOperators: getGridStringOperators().filter(
          (operator) => ['equals', 'isAnyOf'].includes(operator.value),
        ),
      };
    }
    if (col.type === 'singleSelect') {
      return {
        ...col,
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => ['is', 'isAnyOf'].includes(operator.value),
        ),
      };
    }
    return col;
  });
  const isMobile = useIsMobile();
  const flexEndButtonsItem = useMemo(() => {
    if (showExportInvoice && !isMobile) {
      return (
        <DownloadExcelIconButton
          downloadFunction={exportInvoices}
          loading={exportingDocuments}
        />
      );
    }
    return null;
  }, [showExportInvoice, exportInvoices, exportingDocuments, isMobile]);
  const CredentialsError = getErrorType(selectedCompany);
  const showShoppingCartMemo = useMemo(
    () => shoppingCartPreview && selectionModel.length !== 0,
    [shoppingCartPreview, selectionModel],
  );
  if (CredentialsError && type !== ORDERING_DOCUMENTLIST_TYPE && !currentUser.demo) {
    return (
      <CredentialsError />
    );
  }
  return (
    <>
      {CustomHeader ? (
        <CustomHeader
          filters={commonVars}
          financingStatusOptions={financingStatusOptions}
          allCompaniesOptions={allCompaniesOptions}
          states={invoiceFilters}
          setStates={setInvoiceFilters}
          dates={dates}
          setDates={setDates}
          setCustomVariables={setCustomVariablesStates}
          {...customHeaderProps}
        />
      )
        : (
          <PaperHeader
            type={type}
            viewTitle={headerTitle}
            finder={{
              onFinderChange: setGlobalFilter,
              searchPlaceHolder: 'Búsqueda por folio, rut o nombres',
              finderValue: globalFilter,
              canPasteExcel: true,
              setColumnFilter: setInvoiceFilters,
            }}
            helpVideo={helpVideo}
            openJoyride={openJoyride}
            showGoBack={showGoBack}
            intermediateComponent={extraHeaderComponent}
          />
        )}
      {showShoppingCartMemo && (
        <ShoppingCartPreview cartType={type} />
      )}
      {showFilters && (
        <DocumentFilters
          type={type}
          dates={dates}
          setDates={setDates}
          financingStatusOptions={financingStatusOptions}
          allCompaniesOptions={allCompaniesOptions}
          states={invoiceFilters}
          setStates={setInvoiceFilters}
          setCustomVariables={setCustomVariablesStates}
          flexEndButtons={() => flexEndButtons(rowCount, commonVars)}
          canPasteExcel
          showRefresh
          onRefresh={newUpdateDocuments}
          loading={loading}
          openStateFilter={openStateFilter}
          setOpenStateFilter={setOpenStateFilter}
          showExportInvoice
          showExportExcelButton
          exportExcelIconButton={flexEndButtonsItem}
          {...defaultFilterProps}
        />
      )}
      {customSummaryElement}
      {!!(newData && !firstLoad) && (
        <Box display="flex" justifyContent="center" mt={1} position="absolute" width="100%">
          <Button
            variant="contained"
            color="primary"
            size="small"
            endIcon={<Cached />}
            onClick={readDataAndRefresh}
            sx={{
              height: 25,
              zIndex: 100,
            }}
          >
            Tienes {newData} documentos nuevos
          </Button>
        </Box>
      )}
      <Box
        ref={refChange}
        sx={{
          height,
          width: '100%',
        }}
      >
        {firstLoad ? (
          <DataGridNoRowsOverlay Message={FirstLoadMessage} />
        )
          : (
            <FingoDataGrid
              serverFilters={serverFilters}
              loadingWithSkeleton={loading}
              rows={data}
              columns={columns}
              includeHeaders={includeHeaders}
              mobileHeaders={mobileHeaders}
              rowCount={rowCount}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'dateIssued', sort: 'desc' }],
                },
              }}
              pageSize={pageSize}
              disableSelectionOnClick
              setColumnFilter={setInvoiceFilters}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              page={page}
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              checkboxSelection={checkboxSelection}
              disabledCheckboxTooltip={disabledCheckboxTooltip}
              keepNonExistentRowsSelected={checkboxSelection && serverFilters}
              isRowSelectable={isRowSelectable}
              selectionModel={selectionModel}
              loading={loading}
              {...restDataGridProps}
            />
          )}
      </Box>
    </>

  );
};

DocumentList.propTypes = {
  type: PropTypes.string.isRequired,
  headerTitle: PropTypes.string,
  queryDocument: PropTypes.shape().isRequired,
  preColumns: PropTypes.arrayOf(PropTypes.shape()),
  queryExport: PropTypes.shape(),
  checkboxSelection: PropTypes.bool,
  isRowSelectable: PropTypes.func,
  emitter: PropTypes.bool,
  includeHeaders: PropTypes.arrayOf(PropTypes.string),
  mobileHeaders: PropTypes.arrayOf(PropTypes.string),
  showExportInvoice: PropTypes.bool,
  financingStatusOptions: PropTypes.bool,
  updateTrigger: PropTypes.bool,
  setUpdateTrigger: PropTypes.func,
  serverFilters: PropTypes.bool,
  allCompaniesOptions: PropTypes.bool,
  customVariables: PropTypes.shape(),
  initialInvoiceFilters: PropTypes.shape(),
  showFilters: PropTypes.bool,
  defaultFilterProps: PropTypes.shape({
    showPreoffersFilters: PropTypes.bool,
    showQuickFilters: PropTypes.bool,
    showValidSiiCredentialsFilter: PropTypes.bool,
    showLightningFilter: PropTypes.bool,
    showStatesFilter: PropTypes.bool,
    showDatesFilter: PropTypes.bool,
    showRefresh: PropTypes.bool,
    defaultFilterDays: PropTypes.number,
    showCompanyIssuerFilter: PropTypes.bool,
    showHuntingFarmingFilter: PropTypes.bool,
    showInvoiceStatusFilters: PropTypes.bool,
    showExecutiveFilter: PropTypes.bool,
  }),
  shoppingCartPreview: PropTypes.bool,
  customSummaryElement: PropTypes.element,
  flexEndButtons: PropTypes.element,
  // Function that receives row and newValue
  onCompletedSetLength: PropTypes.func,
  onCompletedSetRows: PropTypes.func,
  initialOrderBy: PropTypes.string,
  helpVideo: PropTypes.string,
  openJoyride: PropTypes.func,
  newData: PropTypes.number,
  skipQueryDocument: PropTypes.bool,
  readData: PropTypes.func,
  selectionModel: PropTypes.arrayOf(PropTypes.string),
  CustomHeader: PropTypes.func,
  customHeaderProps: PropTypes.shape(),
  firstLoad: PropTypes.bool,
  countryFilter: PropTypes.bool,
  initialPageSize: PropTypes.number,
  updateCustomVariables: PropTypes.bool,
  showGoBack: PropTypes.bool,
  setUpdateCustomVariables: PropTypes.func,
  disabledCheckboxTooltip: PropTypes.string,
  exportType: PropTypes.string,
  extraHeaderComponent: PropTypes.element,
};

DocumentList.defaultProps = {
  headerTitle: '',
  checkboxSelection: false,
  emitter: false,
  countryFilter: false,
  isRowSelectable: () => true,
  queryExport: EXPORT_INVOICES,
  includeHeaders: [],
  preColumns: [],
  updateTrigger: false,
  setUpdateTrigger: () => null,
  serverFilters: true,
  mobileHeaders: [],
  showExportInvoice: false,
  financingStatusOptions: false,
  allCompaniesOptions: false,
  customVariables: {},
  showGoBack: false,
  skipQueryDocument: undefined,
  initialInvoiceFilters: {},
  showFilters: false,
  defaultFilterProps: {
    showValidSiiCredentialsFilter: false,
    showLightningFilter: false,
    showPreoffersFilters: false,
    showStatesFilter: true,
    showDatesFilter: true,
    showRefresh: true,
    defaultFilterDays: 3,
    showCompanyIssuerFilter: false,
    showHuntingFarmingFilter: false,
    showQuickFilters: false,
    showInvoiceStatusFilters: false,
    buttonsToShow: [],
    showExecutiveFilter: false,
  },
  shoppingCartPreview: false,
  customSummaryElement: null,
  flexEndButtons: () => <></>,
  onCompletedSetLength: (data) => data.invoices.totalCount,
  onCompletedSetRows: (data) => data.invoices.edges.map((edge) => (edge.node)),
  initialOrderBy: '-dateIssued',
  helpVideo: undefined,
  openJoyride: undefined,
  CustomHeader: undefined,
  newData: 0,
  readData: () => {},
  selectionModel: [],
  customHeaderProps: {},
  firstLoad: false,
  initialPageSize: null,
  disabledCheckboxTooltip: null,
  updateCustomVariables: false,
  exportType: 'lending',
  setUpdateCustomVariables: () => {},
  extraHeaderComponent: null,
};

export default DocumentList;
