import React from 'react';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Tabs from './Tabs';
import { FadeSwitch } from '../switches';
import { FullHeightBox } from '../boxes';
import RoundedWhiteBox from '../boxes/RoundedWhiteBox';
import { useAvailableHeight } from '../../hooks';

const FingoPaper = ({ tabs, ...props }) => {
  const { path: routePath } = useRouteMatch();
  const filteredTabs = tabs.filter(({ show, disabled }) => show && !disabled);
  const hasManyTabs = filteredTabs.length > 1;
  const location = useLocation();
  const re = /\/$/;
  const [height, refChange] = useAvailableHeight();
  // eslint-disable-next-line operator-linebreak
  const routeLocation =
    location.pathname.replace(re, '') === routePath
      ? { ...location, pathname: `${routePath}/${filteredTabs[0].path}` }
      : location;

  return (
    <FullHeightBox>
      <Grid
        container
        sx={{
          opacity: hasManyTabs ? '100%' : '0%',
          display: { xs: hasManyTabs ? 'flex' : 'none', md: 'auto' },
          flexWrap: { xs: 'nowrap' },
        }}
      >
        <Tabs tabs={tabs} />
      </Grid>
      <RoundedWhiteBox hasManyOptions={hasManyTabs} height={height} ref={refChange} {...props}>
        <FadeSwitch depth={4} customLocation={routeLocation}>
          {filteredTabs.map(({ id, label, path, component }) => (
            <Route id={id} key={label} path={`${routePath}/${path}`}>
              {component}
            </Route>
          ))}
        </FadeSwitch>
      </RoundedWhiteBox>
    </FullHeightBox>
  );
};

FingoPaper.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.shape()]).isRequired,
      path: PropTypes.string.isRequired,
      component: PropTypes.element.isRequired,
      disabled: PropTypes.bool.isRequired,
      show: PropTypes.bool.isRequired,
      quantity: PropTypes.number,
    }),
  ).isRequired,
};

export default FingoPaper;
