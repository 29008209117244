import { Link } from '@mui/material';
import React from 'react';

const FingoBoldEmail = () => {
  const contactMail = 'contacto@fingo.cl';
  return (
    <Link
      href={`mailto:${contactMail}`}
      underline="hover"
      sx={{
        color: 'black',
        fontWeight: 700,
      }}
    >
      {contactMail}
    </Link>
  );
};

export default FingoBoldEmail;
