import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@mui/material';
import FileChip from '../chips/FileChip';

const FileChipsList = ({
  files,
  uploadable,
  clickable,
  size,
  selectedFileIds,
  setSelectedFileIds,
}) => (
  <List>
    {files.map((file) => (
      <FileChip
        key={file.id}
        file={file}
        clickable={clickable}
        uploadable={uploadable}
        size={size}
        selectedFileIds={selectedFileIds}
        setSelectedFileIds={setSelectedFileIds}
      />
    ))}
  </List>
);

FileChipsList.propTypes = {
  clickable: PropTypes.bool,
  uploadable: PropTypes.bool,
  size: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      avatar: PropTypes.element,
      icon: PropTypes.element,
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedFileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedFileIds: PropTypes.func.isRequired,
};

FileChipsList.defaultProps = {
  clickable: true,
  uploadable: false,
  size: 'medium',
};

export default FileChipsList;
