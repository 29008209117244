import { Box, Stack, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useMemo } from 'react';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIsMobile } from '../../hooks';
import { AvatarContent } from '../dataDisplay';

const TabNavLink = ({ id, label, path, quantity, index, optionsCount }) => {
  const { path: routePath } = useRouteMatch();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const location = useLocation();
  const linkRoute = `${routePath}/${path}`;
  const isActive = useMemo(() => {
    if (location.pathname.includes(linkRoute)) return true;
    if (index === 0) {
      return location.pathname === routePath;
    }
    return false;
  }, [location]);
  const stringToShow = useMemo(() => {
    if (typeof label === 'object' && label.xs) {
      return isMobile ? label.xs : label.md;
    }
    return label;
  }, [label, isMobile]);
  return (
    <Link
      id={id}
      key={label}
      component={NavLink}
      to={linkRoute}
      variant="h2"
      sx={(_theme) => ({
        textDecoration: 'none',
        bgcolor: '#CCCCCC',
        color: 'white',
        p: 0.4,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        ml: 2,
        minWidth: 220,
        maxWidth: 280,
        textAlign: 'center',
        '&:first-of-type': {
          ml: 0,
        },
        [_theme.breakpoints.down('md')]: {
          minWidth: `calc((100% - ${optionsCount}%) / ${optionsCount})`,
          ml: '1%',
          fontSize: 12,
        },
        [_theme.breakpoints.down('s')]: {
          fontSize: 9,
        },
      })}
      activeStyle={{
        backgroundColor: 'white',
        color: theme.palette.primary.main,
      }}
      isActive={(match) => {
        if (match) return true;
        return isActive;
      }}
      noWrap
    >
      <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height="100%">
        <Typography
          variant="subNavItem"
        >{stringToShow}
        </Typography>
        {quantity !== undefined && !isMobile && (
          <AvatarContent content={quantity} active={isActive} />
        )}
      </Stack>
    </Link>
  );
};

TabNavLink.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.shape({
    xs: PropTypes.string,
    md: PropTypes.string,
  })]).isRequired,
  path: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  index: PropTypes.number.isRequired,
  optionsCount: PropTypes.number.isRequired,
};

TabNavLink.defaultProps = {
  quantity: undefined,
  id: undefined,
};

export default TabNavLink;
