import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import isArray from 'lodash/isArray';
import { useLocation } from 'react-router-dom';
import { Collapse } from '@mui/material';
import OnboardingTooltip from '../tooltips/OnboardingTooltip';
import { SidebarItem, LogOutSidebarItem } from '../layout/sidebar/index';

const SidebarItemsList = ({ items, showLogOut, open }) => {
  const location = useLocation();
  return (
    <List>
      {items
        .filter(({ show, showInSidebar }) => show && showInSidebar)
        .map(({ id, label, path, icon, disabled, component }, index) => {
          if (isArray(component)) {
            return (
              <Collapse key={id} in={location.pathname.includes(path)} timeout={100}>
                {component.map(
                  (
                    {
                      id: innerId,
                      label: innerLabel,
                      path: innerPath,
                      icon: innerIcon,
                      disabled: innerDisabled,
                    },
                    innerIndex,
                  ) => (
                    <OnboardingTooltip key={innerId} active={innerDisabled}>
                      <SidebarItem
                        id={innerId}
                        label={innerLabel}
                        path={`${path}/${innerPath}`}
                        icon={innerIcon}
                        disabled={innerDisabled}
                        open={open}
                        index={innerIndex}
                        ml={4}
                      />
                    </OnboardingTooltip>
                  ),
                )}
              </Collapse>
            );
          }
          return (
            <OnboardingTooltip key={id} active={disabled}>
              <SidebarItem
                id={id}
                label={label}
                path={path}
                icon={icon}
                disabled={disabled}
                open={open}
                index={index}
              />
            </OnboardingTooltip>
          );
        })}
      {showLogOut && <LogOutSidebarItem open={open} />}
    </List>
  );
};

SidebarItemsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      path: PropTypes.string.isRequired,
      icon: PropTypes.element,
      component: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
      disabled: PropTypes.bool.isRequired,
      show: PropTypes.bool,
      showInSidebar: PropTypes.bool,
    }),
  ).isRequired,
  open: PropTypes.bool,
  showLogOut: PropTypes.bool,
};

SidebarItemsList.defaultProps = {
  open: false,
  showLogOut: false,
};

export default SidebarItemsList;
