import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Stack, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BankAccountForm from '../forms/BankAccountForm';
import { BankAccountCard } from '../cards';

const useStyles = makeStyles({
  menuItem: {
    overflow: 'unset',
    '&&:hover': {
      backgroundColor: 'inherit',
    },
  },
  button: {
    margin: '1rem',
  },
  buttonCredentials: {
    marginTop: '1rem',
  },
});

const NewBankAccountsList = (props) => {
  const {
    accounts,
    company,
    loading,
    withSelect,
    selectedAccount,
    setSelectedAccount,
    addable,
    editable,
    deletable,
    xs,
    refetch,
  } = props;
  const classes = useStyles();
  const [accountToEdit, setAccountToEdit] = useState(null);
  const [showForm, setShowForm] = useState(null);

  if (showForm) {
    return (
      <BankAccountForm
        mode={showForm}
        cancel={() => { setShowForm(null); setAccountToEdit(null); }}
        accounts={accounts}
        account={accountToEdit}
        onSave={(newAccount) => {
          setSelectedAccount(newAccount);
          setAccountToEdit(null);
          setShowForm(null);
          refetch();
        }}
        company={company}
        refetch={refetch}
      />
    );
  }

  if (loading) return <LinearProgress />;

  return (
    <>
      <Stack spacing={2} sx={{ py: 2 }}>
        {accounts?.length > 0
          ? accounts?.map((account) => (
            <BankAccountCard
              key={account.id}
              account={account}
              withSelect={withSelect}
              selected={selectedAccount?.id === account.id}
              onSelect={() => setSelectedAccount(account)}
              editable={editable}
              editHandler={() => { setAccountToEdit(account); setShowForm('edit'); }}
              deletable={deletable}
            />
          ))
          : (
            <Typography>Debes agregar una cuenta bancaria</Typography>
          )}
        {addable && (
          <Button
            className={xs !== 12 ? classes.button : classes.buttonCredentials}
            variant={xs === 12 ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => { setShowForm('new'); }}
            fullWidth={xs === 12}
          >
            {accounts?.length > 0 ? 'Agregar otra cuenta' : 'Agregar una cuenta'}
          </Button>
        )}
      </Stack>
    </>
  );
};

NewBankAccountsList.propTypes = {
  accounts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  loading: PropTypes.bool.isRequired,
  xs: PropTypes.number,
  company: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  selectedAccount: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  setSelectedAccount: PropTypes.func.isRequired,
  withSelect: PropTypes.bool,
  addable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  refetch: PropTypes.bool.isRequired,
};

NewBankAccountsList.defaultProps = {
  xs: 6,
  selectedAccount: {},
  withSelect: true,
  addable: true,
  editable: true,
  deletable: true,
};

export default NewBankAccountsList;
