import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Stack, Divider } from '@mui/material';
import { useGetBackOfficeUser } from '../../../hooks';
import { currencyVar } from '../../../apollo/reactive-variables';
import { CurrencyFilter } from '../../filters';

const CurrencyNavItem = () => {
  const currency = useReactiveVar(currencyVar);
  const currentUser = useGetBackOfficeUser();
  // Deprecate
  if (!currentUser?.isSuperuser) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center">
      <Divider
        orientation="vertical"
        sx={{ mx: 3, px: 0.02, height: '25px', my: 'auto', bgcolor: (theme) => theme.palette.gray.main }}
        flexItem
      />
      <CurrencyFilter
        currency={currency}
        setCurrency={currencyVar}
      />
    </Stack>
  );
};

export default CurrencyNavItem;
