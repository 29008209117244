import React from 'react';
import PropTypes from 'prop-types';
import {
  ADD_OR_EDIT_CONTACT,
  GET_MASTER_ENTITY_CONTACTS,
} from '../../graphql';
import { ArrayOfId, MasterEntityType } from '../../propTypes';
import CompanyContactsFormGrid from '../contacts/CompanyContactsFormGrid';

const CompanyContactList = ({ masterEntity, setSelectedContactsIds, selectedContactId }) => (
  <CompanyContactsFormGrid
    queryDocument={GET_MASTER_ENTITY_CONTACTS}
    createQueryDocument={ADD_OR_EDIT_CONTACT}
    masterEntity={masterEntity}
    checkbox
    selectedContacts={selectedContactId}
    setSelectedContact={setSelectedContactsIds}
  />
);

CompanyContactList.propTypes = {
  setSelectedContactsIds: PropTypes.func.isRequired,
  masterEntity: MasterEntityType.isRequired,
  selectedContactId: ArrayOfId.isRequired,
};

export default CompanyContactList;
